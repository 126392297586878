import React, { useEffect } from 'react';
import './FireWarden.css';
import { SlArrowRightCircle } from "react-icons/sl";
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS CSS

import companyImage from '../../assets/firewarden.jpeg'; 
import { useNavigate } from 'react-router-dom'
import MainComp from './MainFireComp';

const FireWarden = () => {
  const navigate = useNavigate(); 

  useEffect(() => {
    AOS.init({
      duration: 1000, // Change the animation duration as needed
      disable: window.innerWidth <= 768, // Disable AOS on screens smaller than or equal to 768px
    });
  }, []);
  
  const handleNavigation = (path) => {
    navigate(path); 
  };

  return (
    <>
  <MainComp/>
    <div className="FireWarden-container" data-aos="fade-up"> {/* Add AOS attribute */}
      <div className="FireWarden-left-content" data-aos="fade-right">
        <h1> FIRE WARDENS</h1>
        <div className='FireWarden-company-info'>
        Providing a safe shopping environment and reducing theft losses are critical for retail organisations. Our retail security and loss prevention management services are specifically designed to address the issues that merchants encounter.
        <br/>
        <br/>
        We take a multifaceted approach, combining monitoring, theft detection, and retail security. Our professional security officers are trained to detect suspicious behaviour, prevent theft, and respond quickly and discreetly to any security events. By cooperating with JRSSA, you can secure your merchandise, increase your revenue, and provide a safe and welcome environment for your customers.
        </div>
        
        <button className="FireWarden-about-company-button" onClick={() => handleNavigation('/contact-us')} data-aos="zoom-in"> {/* Add AOS attribute */}
          Contact Us
          <SlArrowRightCircle className="FireWarden-rightcircle-icon"  />
        </button>
      </div>
      <div className="FireWarden-right-content" data-aos="fade-up"> {/* Add AOS attribute */}
        <img className="FireWarden-company-image" src={companyImage} alt="Company" />
      </div>
    </div>
    </>
  );
};

export default FireWarden;
