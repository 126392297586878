import React from "react";
import MainComp from "../components/ContactUs/MainComp";
import ContactUs1Comp from "../components/ContactUs/ContactUs1Comp";
import ContactUs2Comp from "../components/ContactUs/ContactUs2Comp";


const ContactUs = () => {
  return (
    <div>
     <MainComp/>
    <ContactUs1Comp/>
    <ContactUs2Comp/>
    </div>
  );
};

export default ContactUs;
