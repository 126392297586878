import React, { useEffect } from 'react';
import './ContactUs2Comp.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

const ContactUs2Comp = () => {
    useEffect(() => {
        AOS.init({
            duration: 1000, // Animation duration
            easing: 'ease-in-out', // Easing option
            once: false, // Whether animation should occur only once
            mirror: false // Whether elements should animate out while scrolling past them
        });
    }, []);

    return (
        <div className="maps-container" data-aos="fade-up">
            <h5 className="maps-mini-title" data-aos="fade-up" data-aos-delay="100">Maps</h5>
            <h2 className="maps-heading" data-aos="fade-up" data-aos-delay="200">FIND US ON MAPS</h2>
            <div className="maps-frame" data-aos="fade-up" data-aos-delay="300">
            <iframe
    title="Company Location"
    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3446.170800962531!2d149.04797355771174!3d-35.1995883702912!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b17ac51eb88c94b%3A0xb23d2b1530c93202!2s49%20Spalding%20St%2C%20Flynn%20ACT%202615%2C%20Australia!5e0!3m2!1sen!2sin!4v1718271436511!5m2!1sen!2sin&t=k"
    width="100%"
    height="450"
    style={{ border: 0 }}
    allowFullScreen=""
    loading="lazy"
></iframe>


            </div>
        </div>
    );
};

export default ContactUs2Comp;
