import React, { useEffect } from 'react';
import { SlArrowRightCircle } from "react-icons/sl";
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS CSS

import companyImage from '../../assets/cctv.jpg'; 
import { useNavigate } from 'react-router-dom'
import MainComp from './MainFireComp';

const Cctv = () => {
  const navigate = useNavigate(); 

  useEffect(() => {
    AOS.init({
      duration: 1000, // Change the animation duration as needed
      disable: window.innerWidth <= 768, // Disable AOS on screens smaller than or equal to 768px
    });
  }, []);
  
  const handleNavigation = (path) => {
    navigate(path); 
  };

  return (
    <>
  <MainComp/>
    <div className="FireWarden-container" data-aos="fade-up"> {/* Add AOS attribute */}
      <div className="FireWarden-left-content" data-aos="fade-right">
        <h1> Cctv Operations</h1>
        <div className='FireWarden-company-info'>
        We perform both reactive and preventative maintenance for CCTV systems as well as provide and install them. We deploy CCTV monitoring and response systems to assist defend the safety and integrity of your business, as well as the wellbeing of your employees and clients, as well as public liability, asset and property protection.
        <br/>
        <br/>
        When compared to traditional physical security systems, our team of highly qualified Control Center operators and CCTV/Alarm Technicians will provide you the peace of mind you need to run your business successfully while saving you money.
        <br/>
        When compared to traditional physical security systems, our team of highly qualified Control Center operators and CCTV/Alarm Technicians will provide you the peace of mind you need to run your business successfully while saving you money.
        </div>
        
        <button className="FireWarden-about-company-button" onClick={() => handleNavigation('/contact-us')} data-aos="zoom-in"> {/* Add AOS attribute */}
          Contact Us
          <SlArrowRightCircle className="FireWarden-rightcircle-icon"  />
        </button>
      </div>
      <div className="FireWarden-right-content" data-aos="fade-up"> {/* Add AOS attribute */}
        <img className="FireWarden-company-image" src={companyImage} alt="Company" />
      </div>
    </div>
    </>
  );
};

export default Cctv;
