import React from 'react';
import './CustomerReviews.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteLeft, faQuoteRight } from '@fortawesome/free-solid-svg-icons';

const reviews = [
  {
    text: "Our branch has had a number of guards attend on a number of occasions but I have honestly never had the experience I have been having with Omer. He is attentive, friendly, professional and exceeds my expectations of a guard in branch. The branch is currently going through a refurbishment and he is here to support us with the limited line of sight and be prepared for any potential aggressive customers. But what Omer is doing to assist us in terms of COVID19 restrictions is a stand out for me. He is assisting guide customer traffic in branch, he has helped with counting and limiting capacity which has been a real challenge for us.",
    author: "Caitlin Day",
    position: "Branch Manager, Belconnen Mall"
  },
  {
    text: "RAR Group have been using the services of National Capital Security Group for the past 2 years and I can say with confidence that their work ethic is exceptional. Hamid Monga has been a great work associate and is always very responsive and readily approachable. I highly recommend the services of National Capital Security Group to anyone for future business.",
    author: "Camilla Abbey",
    position: "Manager, RAR Group"
  },
  {
    text: "It’s an amazing transformation. I am glad that all the hard work that each and every one of you had been putting in, is showing up now. All of you should be proud of what we have achieved. In compliance also, we are regularly among top 3 States of the Nation. Exceptional work.",
    author: "Faizzan Zafar",
    position: "Operations Coordinator"
  },
  {
    text: "We have found that at anytime an out of schedule Guard is required NCSG has always been able to provide Staff. At times with as little as 2hrs notice. From a management perspective we have found the administration to be as professional as the Guards. All invoices are fully detailed, correct and on time. It is without hesitation that I can recommend Hamid Monga and National Capital Security Group. I would be happy to discuss my recommendation with any interested party.",
    author: "Michael Theodorakis",
    position: "Store Co-Owner"
  }
];

const CustomerReviews = () => {
  return (
    <div className="customer-reviews-container">
      <h2 className="reviews-title">Customer Reviews</h2>
      <div className="reviews-grid">
        {reviews.map((review, index) => (
          <div key={index} className="review-card">
            <FontAwesomeIcon icon={faQuoteLeft} className="quote-icon left" />
            <p className="review-text">{review.text}</p>
            <FontAwesomeIcon icon={faQuoteRight} className="quote-icon right" />
            <p className="review-author">- {review.author}</p>
            <p className="review-position">{review.position}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CustomerReviews;
