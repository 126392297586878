import React from "react";
import MainComp from "../components/Clients/MainComp";
import Client1Comp from "../components/Clients/Client1Comp";


const ClientsPage = () => {
  return (
    <div>
  <MainComp/>
  <Client1Comp/>
    
    </div>
  );
};

export default ClientsPage;
