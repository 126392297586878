import React, { useEffect } from "react";
import "./About1comp.css";
import sideImage from "../../assets/1.jpg";
import secondImage from "../../assets/2.jpg";
import AOS from "aos";
import "aos/dist/aos.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

const About1comp = () => {
  useEffect(() => {
    AOS.init({ once: false }); // Initialize AOS once
  }, []);

  return (
    <div className="whowe-image ">
      <div
        className="whowe1-container"
        data-aos="fade-up"
        data-aos-duration="1000"
      >
        <div className="whoweare-section">
          <h2>About Us :</h2>
          <div className="whowepara-section">
            <div className="whoweare-para">
              National Capital Security Group was founded in 2019. Hamid has
              been in the security industry for the past 10 + years holding NV1
              Security Clearances. Being working as a second job of a security
              guard for a decade, Hamid came across many different Canberra
              companies. Due to unsatisfactory service and insufficient high
              attention to detail issues with the companies, he decided to open
              up his own company and change the traditional way security is
              carried out by introducing an "at your service" attitude within
              his company's security team
            </div>
          </div>

          <div
            className="secondary-section"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <div className="side-images">
              <img
                src={sideImage}
                alt="Side"
                className="side-image"
                data-aos="fade-right"
                data-aos-duration="2000"
              />
              <img
                src={secondImage}
                alt="Side"
                className="side-image"
                data-aos="fade-left"
                data-aos-duration="2000"
              />
            </div>

           
     
   </div>
   </div>
   </div>
   </div>
   
  );
};

export default About1comp;
