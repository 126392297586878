// MainComp.jsx

import React, { useEffect } from 'react';
import './MainComp.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

const MainComp = () => {
  useEffect(() => {
    AOS.init({ duration: 2000 }); // Initialize AOS and set duration for the animation
  }, []);

  return (
    <div className="mainNcsg-section">
      <div className="overlay"></div> {/* Add overlay here */}
      <div className='main-title-Ncsgsection'>
        <h1 className="main-ncsgtitle" data-aos="fade-down">WHY NCSG</h1> {/* Add fade-down AOS effect here */}
      </div>
    </div>
  );
};

export default MainComp;
