// MainComp.jsx

import React, { useEffect } from 'react';
import './MainComp.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

const MainComp = () => {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <div className="mainCareer-section">
      <div className="overlay"></div>
      <div className='main-title-Careersection'>
        <h1 className="mainCareer-title" data-aos="fade-down">Join Us Today</h1>
      </div>
    </div>
  );
};

export default MainComp;
