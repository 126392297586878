import React, { useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Brandbar from './components/BrandBar/BrandBar';
// import Header from './components/Header/Header'
import Home from './pages/Home';
import Footer1 from './components/Footer1/Footer1';
import About from './pages/About';
import Header from './components/Header/Header'
import WhyncsgPage from './pages/WhyncsgPage';
import MobilePatrol from './components/Services/MobilePatrol/MobilePatrol';// import Navbar from './components/Navbar/Navbar';
import FireWarden from './components/Services/FireWardens/FireWardens';
import ContactUs from "./pages/ContactUs";
import ClientsPage from "./pages/ClientsPage";
import CareerPage from "./pages/CareerPage";
import StickyChat from './components/StickyChat/StickyChat'
import ScrollToTopButton from './components/ScrollToTopButton/ScrollToTopButton'
import Cctv from "./components/Services/Cctv/Cctv";
import Concierge from "./components/Services/Concierge/Concierge";
import OtherServices from "./components/Services/OtherServices/OtherServices";
function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
    <Router>
      <div>
      <ScrollToTop />
      <ScrollToTopButton/>
      {/* <StickyChat/> */}

        <Brandbar />
       
        <Header/>
       <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/why-ncsg" element={<WhyncsgPage />} />
          <Route path="/mobile-patrol" element={<MobilePatrol/>} />
          <Route path="/fire-wardens" element={<FireWarden/>} />
          <Route path="/cctv-operations" element={<Cctv/>} />
          <Route path="/concierge-services" element={<Concierge/>} />
          <Route path="/other-services" element={<OtherServices/>} />



          

          <Route path="/contact-us" element={<ContactUs/>} />
          <Route path="/clients" element={<ClientsPage/>} />
          <Route path="/careers" element={<CareerPage/>} />





        </Routes>
        <Footer1 />
      </div>
    </Router>
  );
}

export default App;
