import React, { useState, useEffect } from 'react';
import './ScrollToTopButton.css'; // Import your CSS file for styling
import { SlArrowUp } from "react-icons/sl"; // Import the SlArrowUp icon

const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsVisible(window.scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <div className={`scroll-to-top zoomer-mover ${isVisible ? 'show' : ''}`} onClick={scrollToTop}>
      <SlArrowUp  className='up-icon '/> {/* Replace the Font Awesome icon with SlArrowUp */}
    </div>
  );
};

export default ScrollToTopButton;
