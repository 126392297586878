import React from 'react';
import './Career1Comp.css';

const Career1Comp = () => {
    return (
        <div className="career-container">
            <div className="career-content">
                <h2 className="career-main-heading">Join Us</h2>
                <p className="career-message">
                    To apply, please email your resume to <a href="mailto:careers@example.com">patrols.canberra@outlook.com</a> or fill up the form below.
                </p>
            </div>
        </div>
    );
};

export default Career1Comp;
