export const points = [
  {
    text: 'We take pride in our work.'
  },
  {
    text: 'We do not "subcontract" our work.'
  },
  {
    text: 'We are always available to our clients with a "24/7 at your service, sir" attitude. Our leadership team is humble, polite, and extremely approachable.'
  },
  {
    text: 'We develop an SOP for all our jobs which every security guard in the company always uses. Before every shift, they get briefed over their shift-related tasks, duties, and broader responsibilities. Our staff member still calls them when they reach on-site to see if they have any issues.'
  },
  {
    text: 'Our rostering system is one of the best in the industry. It is called "Deputy". Every location is geographically locked with Google Maps\' help ensuring that the guard reaches the exact shift location. It is also linked with the payroll system as it has real-time award entered in itself, ensuring that payroll is always correct.'
  },
  {
    text: 'We do not subcontract our work out. Hence quality is ensured by hiring our employees. This means that our guards are never "OVERWORKED"; our clients and guards are permanent; therefore, there are no surprises.'
  },
  {
    text: 'We do a thorough reference check on our guards. We have an intense interview process in which we rank the guards against the criteria defined by our clients.'
  }
];
