import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';

import './Home4Comp.css';
import whychoose from '../../assets/3.jpg';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons'; 
import { SlArrowRightCircle } from "react-icons/sl";

const Home4Comp = () => {
  const navigate = useNavigate();

  useEffect(() => {
    AOS.init({
      duration: 1000, 
      easing: 'ease-in-out', 
      once: false, 
    });
  }, []);

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <div className="why-choose-us-container">
      <div className="whychoose-image-container" data-aos="fade-up">
        <svg width="95%" height="auto" className="left-image " data-aos="fade-up">
          <image href={whychoose} width="100%" height="360px" className='whychoose-img zoomer-mover' />
        </svg>
      </div>
      <div className="points-container">
        <div className="point" data-aos="fade-up">
          <div className="bullet-image">            <FontAwesomeIcon icon={faCheck} className="bullet-point" />
</div>
          <div className="point-text">Trusted expertise in comprehensive security solutions.</div>
        </div>
        <div className="point" data-aos="fade-up" data-aos-delay="200">
          <div className="bullet-image"><FontAwesomeIcon icon={faCheck} className="bullet-point" /></div>
          <div className="point-text">Proven track record of delivering tailored security strategies.</div>
        </div>
        <div className="point" data-aos="fade-up" data-aos-delay="400">
          <div className="bullet-image"><FontAwesomeIcon icon={faCheck} className="bullet-point" /></div>
          <div className="point-text">Dedicated to safeguarding clients' assets with cutting-edge technology.</div>
        </div>
        <div className="point" data-aos="fade-up" data-aos-delay="600">
          <div className="bullet-image"><FontAwesomeIcon icon={faCheck} className="bullet-point" /></div>
          <div className="point-text">Commitment to unparalleled customer service and satisfaction.</div>
        </div>
        <div className="point" data-aos="fade-up" data-aos-delay="800">
          <div className="bullet-image"><FontAwesomeIcon icon={faCheck} className="bullet-point" /></div>
          <div className="point-text">Industry-leading professionals ensuring peace of mind for every client.</div>
        </div>
        <div className="point" data-aos="fade-up" data-aos-delay="1000">
          <div className="bullet-image"><FontAwesomeIcon icon={faCheck} className="bullet-point" /></div>
          <div className="point-text">Cost-effective Solutions</div>
        </div>
        <button className="learn-more-button" onClick={() => handleNavigation('/why-ncsg')}>
          SEE MORE 
          <SlArrowRightCircle className="learn-more-icon" />
        </button>
      </div>
    </div>
  );
};

export default Home4Comp;
