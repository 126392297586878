import React from "react";
import MainComp from "../components/Careers/MainComp";
import Career1Comp from "../components/Careers/Career1Comp";
import Career2Comp from "../components/Careers/Career2Comp";



const CareerPage = () => {
  return (
    <div>
  <MainComp/>
  <Career1Comp/>
  <Career2Comp/>c
    
    </div>
  );
};

export default CareerPage;
