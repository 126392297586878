// MainMobileComp.jsx

import React, { useEffect } from 'react';
import './MainMobileComp.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

const MainMobileComp = () => {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <div className="mainMobile-section">
      <div className="overlay"></div>
      <div className='mainMobile-title-section'>
        <h1 className="mainMobile-title" data-aos="fade-down">MOBILE PATROL</h1>
      </div>
    </div>
  );
};

export default MainMobileComp;
