import React, { useEffect, useState } from "react";
import { IoMdMail } from "react-icons/io";
import "./BrandBar.css"; 

import logo from '../assets/updatedlogo.png';
import fb from '../assets/fb.png';
import ig from '../assets/ig.png';
import ind from '../assets/in.png';
import tw from '../assets/tw.png';



const Brandbar = () => {
  const [typedText, setTypedText] = useState("");
  const text = "Comprehensive Security for Every Sector.";
  const typingSpeed = 100; // Adjust typing speed as needed
  const delayBeforeTyping = 1000; // Delay before starting typing animation

  useEffect(() => {
    let isUnmounted = false;

    const typeText = async () => {
      if (isUnmounted) return;
      for (let i = 0; i <= text.length; i++) {
        setTypedText(text.substring(0, i));
        await new Promise((resolve) => setTimeout(resolve, typingSpeed));
      }
      await new Promise((resolve) => setTimeout(resolve, delayBeforeTyping));
      setTypedText("");
      typeText();
    };

    setTimeout(typeText, delayBeforeTyping);

    return () => {
      isUnmounted = true;
    };
  }, []);

  return (
    <section1 className="flex flex-col md:flex-row justify-between items-center px-4 sm:px-6 md:px-28 py-2 background-image bg-[#f8f9fa] brandbar-section">
      <div className="flex flex-col md:flex-row items-center md:items-start text-center md:text-left">
      <img
  className="mx-auto md:mx-0 brand-image"
  src={logo}
  alt="brand logo"
  style={{ width: '250px', height: 'auto' }} // Adjust these values as needed
/>

<div className="mt-1 lg:mt-16 md:ml-4 brandbar-middle">
  <h2 className="font-bold text-xl md:text-2xl lg:text-3xl text-[#000000] mt-4 brand-heading">
    National Capital Security Group 
  </h2>
  <span className="lg:text-xl  hide-it">Greenwich Integrated Services</span> 

          <h3 className="font-extralight text-sm md:text-lg mt-6 brandmini-heading  text-[#ffffff]">{typedText}</h3>
        </div>
      </div>

      <div className=" md:mt-0 brandbar-end">
  <div className="flex justify-center md:justify-start items-center gap-2 md:gap-4">
    <IoMdMail size={24} className="text-lg md:text-2xl brand-contact" />
    <a
      className="underline text-xs md:text-sm"
      href="mailto:patrols.canberra@outlook.com"
    >
      patrols.canberra@outlook.com
    </a>
  </div>
  <div className="flex justify-center md:justify-center items-center gap-2 md:gap-4 mt-2">
    <span className="text-xs md:text-sm">+61 1300 10 70 60</span> {/* Replace with actual contact number */}
  </div>
  <div className="flex justify-center mt-2 md:mt-3 gap-3 md:gap-4 brand-icons">
    <a href="https://facebook.com" rel="noreferrer" target="_blank">
      <img src={fb} alt="fbnav" className="w-5 md:w-6" />
    </a>
    <a href="https://instagram.com" rel="noreferrer" target="_blank">
      <img src={ig} alt="ignav" className="w-5 md:w-6" />
    </a>
    <a href="https://twitter.com" rel="noreferrer" target="_blank">
      <img src={tw} alt="twnav" className="w-5 md:w-6" />
    </a>
    <a href="https://linkedin.com" rel="noreferrer" target="_blank">
      <img src={ind} alt="lnnav" className="w-5 md:w-6" />
    </a>
  </div>
</div>

    </section1>
  );
};

export default Brandbar;
