import React from 'react';
import logo from '../assets/updatedlogo.png';
import './Footer1.css';

const Footer1 = () => {
  return (
    <footer className="main-footer">
      <div className="container">
        <div className="footer-content">
          <div className="footer-row">
            <div className="footer-column logo-column">
              <div className="logo-widget footer-widget">
                <figure className="logo-box">
                  <a href="#"><img src={logo} alt="Logo" /></a>
                </figure>
                <div className="text">
                  {[ 'Greenwich Integrated Services', '', ''].map((contact, index) => (
                    <p key={index}>
                      {contact}
                    </p>
                  ))}
                </div>
              </div>
            </div>
            <div className="footer-column contact-column">
              <div className="contact-widget footer-widget">
                <div className="footer-title">Licences</div>
                <div className="text">
                  <ul>
                    {['Master License NCSG Number: 17502594', '', 'ASIAL Membership Number: 63889', 'Master Licence Number: 000105471', 'ABN: 60 632 893 184'].map((item, index) => (
                      <li key={index}>
                        {item}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="footer-column address-column">
              <div className="address-widget footer-widget">
                <div className="footer-title">Address</div>
                <div className="text">
                  <p>49 Spalding Street, Flynn ACT 2615,<br />
            
                  Australian Capital Territory, <br/> Australia<br />
                    Call: +61 1300 10 70 60</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="footer-bottom-row">
            <div className="footer-bottom-content">
              <ul className="list horizontal-list">
                {['Home', 'About Us', 'Services', 'Clients', 'Career', 'Contact Us'].map((link, index) => (
                  <li key={index}>
                    <a href="#">{link}</a>
                  </li>
                ))}
              </ul>
              <ul className="footer-social">
                {['facebook-f', 'twitter', 'vimeo-v', 'google-plus-g'].map((icon, index) => (
                  <li key={index}>
                    <a href="#"><i className={`fab fa-${icon}`}></i></a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer1;
