import React, { useEffect } from 'react';
import './MobilePatrol.css';
import { SlArrowRightCircle } from "react-icons/sl";
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS CSS

import companyImage from '../../assets/patrolling.jpeg.png'; 
import { useNavigate } from 'react-router-dom'
import MainComp from './MainMobileComp';

const MobilePatrol = () => {
  const navigate = useNavigate(); 

  useEffect(() => {
    AOS.init({
      duration: 1000, // Change the animation duration as needed
      disable: window.innerWidth <= 768, // Disable AOS on screens smaller than or equal to 768px
    });
  }, []);
  
  const handleNavigation = (path) => {
    navigate(path); 
  };

  return (
    <>
  <MainComp/>
    <div className="mobilePatrol-container" data-aos="fade-up"> {/* Add AOS attribute */}
      <div className="mobilePatrol-left-content" data-aos="fade-right">
        <h1> Mobile Patrols</h1>
        <div className='mobilePatrol-company-info'>
        The National Capital Security Group is backed by Australia's largest fleet of marked security vehicles, which conduct random inspections of unattended assets while ensuring the most efficient response to duress and alarm activations through the use of cutting-edge despatch, navigation, and reporting technology.
        <br/>
        We take a multifaceted approach, combining monitoring, theft detection, and retail security. Our professional security officers are trained to detect suspicious behaviour, prevent theft, and respond quickly and discreetly to any security events. By cooperating with JRSSA, you can secure your merchandise, increase your revenue, and provide a safe and welcome environment for your customers.
        </div>
        
        <button className="mobilePatrol-about-company-button" onClick={() => handleNavigation('/contact-us')} data-aos="zoom-in"> {/* Add AOS attribute */}
          Contact Us
          <SlArrowRightCircle className="mobilePatrol-rightcircle-icon"  />
        </button>
      </div>
      <div className="mobilePatrol-right-content" data-aos="fade-up"> {/* Add AOS attribute */}
        <img className="mobilePatrol-company-image" src={companyImage} alt="Company" />
      </div>
    </div>
    </>
  );
};

export default MobilePatrol;
