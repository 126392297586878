// MainFireComp.jsx

import React, { useEffect } from 'react';

import AOS from 'aos';
import 'aos/dist/aos.css';

const MainFireComp = () => {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <div className="mainFire-section">
      <div className="overlay"></div>
      <div className='mainFire-title-section'>
        <h1 className="mainFire-title" data-aos="fade-down">Concierge & Customer Service</h1>
      </div>
    </div>
  );
};

export default MainFireComp;
