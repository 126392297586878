import React from "react";
import MainComp from "../components/About/Whyncsg/MainComp/MainComp";
import WhyncsgComp1 from "../components/About/Whyncsg/WhyncsgComp1";


const WhyncsgPage = () => {
  return (
    <div>
     <MainComp/>
     <WhyncsgComp1/>
    </div>
  );
};

export default WhyncsgPage;
