import React, { useState, useEffect } from 'react';
import './ContactUs1Comp.css';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

const ContactUs1Comp = () => {
  const initialFormData = {
    name: '',
    phoneNumber: '',
    email: '',
    subject: '',
    message: ''
  };

  const [formData, setFormData] = useState(initialFormData);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: false
    });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
    // Validate field in real-time if needed
    validateField(name, value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formErrors = validateForm(formData);
    if (Object.keys(formErrors).length === 0) {
      try {
        setIsLoading(true);
        // Transform the form data to match backend schema
        const transformedData = {
          EnquiryId: Date.now(),
          Name: formData.name,
          PhoneNumber: formData.phoneNumber,
          EmailId: formData.email,
          Subject: formData.subject,
          message: formData.message
        };

        // Log the form data being sent to the backend
        console.log('Form data being sent to the backend:', transformedData);

        // Send form data to backend
        const response = await axios.post('https://ncsgsecurityproject.vercel.app/api/v1/CreateenquiryDetails', transformedData);

        // Log the response from backend
        console.log('Response from backend:', response.data);

        // Ensure response includes all the necessary fields and handle accordingly
        if (response.data && response.data.success) {
          const { message, data } = response.data;
          toast.success(message);
          // Optionally, handle `data` if needed
          console.log('Enquiry data:', data);
          setFormData(initialFormData);
          setErrors({});
        } else {
          toast.error(response.data.message || 'Error creating enquiry. Please try again.');
        }

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error('Error submitting form:', error);
        toast.error('Error submitting form. Please try again later.');
      }
    } else {
      setErrors(formErrors);
      toast.error('Please fill in all required fields.');
    }
  };

  const validateForm = (data) => {
    let formErrors = {};
    // Validate each field
    if (!data.name.trim()) {
      formErrors.name = 'Name is required';
    }
    if (!data.phoneNumber.trim()) {
      formErrors.phoneNumber = 'Phone number is required';
    } else if (!/^\+?[0-9\s\-]{7,15}$/.test(data.phoneNumber)) {
      formErrors.phoneNumber = 'Invalid phone number';
    }
    if (!data.email.trim()) {
      formErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(data.email)) {
      formErrors.email = 'Invalid email address';
    }
    if (!data.subject.trim()) {
      formErrors.subject = 'Subject is required';
    }
    if (!data.message.trim()) {
      formErrors.message = 'message is required';
    }
    return formErrors;
  };

  const validateField = (fieldName, value) => {
    let fieldErrors = {};
    switch (fieldName) {
      case 'email':
        if (!/\S+@\S+\.\S+/.test(value)) {
          fieldErrors.email = 'Invalid email address';
        }
        break;
      case 'phoneNumber':
        if (!/^\+?[0-9\s\-]{7,15}$/.test(value)) {
          fieldErrors.phoneNumber = 'Invalid phone number';
        }
        break;
      // Add more field-specific validation if needed
      default:
        break;
    }
    setErrors({ ...errors, [fieldName]: fieldErrors[fieldName] });
  };

  return (
    <div className="contact-us-container">
      <div id="contact-form-section" className="contact-sections">
        {/* Left Section */}
        <div className="contact-info-section" data-aos="fade-up">
          <h2 className="contact-info-heading" data-aos-delay="200">Enquire Our Service Today</h2>
          <div className='contact-info-text-main'>
            <p className="contact-info-text" data-aos="fade-up" data-aos-delay="300">
              Get in touch with <span className='span-bold'>National Capital Security Group</span> for reliable and dedicated security services. We're available 24/7 to meet your needs with care and attention to detail. Contact us today to see how we can help keep you safe.
            </p>
          </div>
          <div className='contact-detail-section' style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <a href="tel:+1234567890"><i className="fas fa-phone"></i> +61 1300 10 70 60</a>
            <a href="mailto:patrols.canberra@outlook.com"><i className="fas fa-envelope"></i> patrols.canberra@outlook.com</a>
          </div>
          <div className="career-info-item">
            <i className="fas fa-map-marker-alt career-icons"></i>
            <div>
              49 Spalding Street, <br /> Flynn ACT 2615,<br />
              Australian Capital Territory,<br />
              Australia.
            </div>
          </div>
        </div>

        {/* Right Section */}
        <div className="contact-input-section" data-aos="fade-up" data-aos-delay="400">
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              name="name"
              placeholder="Your Name"
              className={`contact-input ${errors.name && 'input-error'}`}
              value={formData.name}
              onChange={handleChange}
            />
            {errors.name && <div className="error-message">{errors.name}</div>}
            <input
              type="tel"
              name="phoneNumber"
              placeholder="Phone Number"
              className={`contact-input ${errors.phoneNumber && 'input-error'}`}
              value={formData.phoneNumber}
              onChange={handleChange}
            />
            {errors.phoneNumber && <div className="error-message">{errors.phoneNumber}</div>}
            <input
              type="email"
              name="email"
              placeholder="Email-id"
              className={`contact-input ${errors.email && 'input-error'}`}
              value={formData.email}
              onChange={handleChange}
            />
            {errors.email && <div className="error-message">{errors.email}</div>}
            <input
              type="text"
              name="subject"
              placeholder="Subject"
              className={`contact-input ${errors.subject && 'input-error'}`}
              value={formData.subject}
              onChange={handleChange}
            />
            {errors.subject && <div className="error-message">{errors.subject}</div>}
            <textarea
              name="message"
              placeholder="Your message"
              className={`contact-textarea ${errors.message && 'input-error'}`}
              value={formData.message}
              onChange={handleChange}
            ></textarea>
            {errors.message && <div className="error-message">{errors.message}</div>}
            {isLoading ? (
              <button type="button" className="contact-submit" disabled>Sending...</button>
            ) : (
              <button type="submit" className="contact-submit">Submit</button>
            )}
            <ToastContainer />
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactUs1Comp;
