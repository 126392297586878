import React, { useState } from 'react';
import './FAQComponent.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleRight } from '@fortawesome/free-solid-svg-icons';

const FAQComponent = () => {
  const [openFaqId, setOpenFaqId] = useState(null);

  const toggleFaq = (id) => {
    setOpenFaqId((prevId) => (prevId === id ? null : id));
  };

  const faqArr = [
    {
      id: 1,
      que: "What sets National Capital Security Group apart from other security companies?",
      ans: "National Capital Security Group prioritizes accountability, avoids subcontracting, and offers a personalized 'at your service' approach.",
    },
    {
      id: 2,
      que: "How does National Capital Security Group ensure quality in its services?",
      ans: "We hire our own employees, conduct thorough reference checks, and provide intense interviews to match guard criteria defined by clients.",
    },
    {
      id: 3,
      que: "What is the rostering system used by National Capital Security Group?",
      ans: "We utilize the 'Deputy' system, geographically locking locations and linking with payroll for real-time accuracy.",
    },
    {
      id: 4,
      que: "Does National Capital Security Group subcontract its work?",
      ans: "No, We do not subcontract, ensuring quality control and preventing guards from being overworked.",
    },
    {
      id: 5,
      que: "What is the advantage of National Capital Security Group's flat hierarchy structure?",
      ans: "It ensures fast information flow, prompt feedback, and eliminates bureaucratic red tape.",
    },
    {
      id: 6,
      que: "How does National Capital Security Group handle client requests for security guards?",
      ans: "Clients contact a designated point of contact, who arranges guards and provides detailed job instructions via email and phone.",
    },
    {
      id: 7,
      que: "What measures does National Capital Security Group take to ensure client satisfaction?",
      ans: "We maintain small client bases for focused service, provide advance rosters, and offer open communication channels for feedback.",
    },
    {
      id: 8,
      que: "What distinguishes National Capital Security Group's rostering system?",
      ans: "It prevents double bookings, geographically locks sites, and automatically calculates shift times and applicable rates.",
    },
    {
      id: 9,
      que: "How does National Capital Security Group support its guard team?",
      ans: "We offer rewards, facilitate additional shifts for current staff, and provide continuous communication and support.",
    },
    {
      id: 10,
      que: "Why should you choose National Capital Security Group for your security needs?",
      ans: "We prioritize quality, offer seamless invoicing, ensure guards are well-treated and trained, and guarantee satisfaction with their services.",
    },
  ];

  return (
    <section className="p-4 md:p-14 faq-section" data-aos="fade-up" data-aos-delay="600" >
      <h2 className="text-[#cb9804] text-center font-normal sm:font-bold text-2xl tracking-widest mb-4">
  Frequently Asked Questions
</h2>

      {faqArr.map((faq, index) => (
        <div
          key={faq.id}
          className="border border-gray-500 rounded-md mb-4"
          onClick={() => toggleFaq(faq.id)}
          data-aos="fade-up"
          data-aos-delay={`${700 + index * 100}`}
        >
          <div className="flex justify-between items-center">
            <p className="p-4 font-normal sm:font-semibold cursor-pointer rounded-t-md">
              {faq.que}
            </p>
            <FontAwesomeIcon
              icon={openFaqId === faq.id ? faAngleDown : faAngleRight}
              className="text-black-600 mr-4 font-semibold cursor-pointer"
            />
          </div>
          {openFaqId === faq.id && (
            <p className="rounded-b-md text-justify px-4 py-2 text-gray-700">
              {faq.ans}
            </p>
          )}
        </div>
      ))}
    </section>
  );
};

export default FAQComponent;