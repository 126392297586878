import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import './Header.css';
import Logo from '../assets/logo-removebg-preview.png'

function Header() {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 991);
  const isTouchDevice = 'ontouchstart' in document.documentElement;

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 991);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleDropdownToggle = (dropdownName) => {
    setActiveDropdown((prevDropdown) => (prevDropdown === dropdownName ? null : dropdownName));
  };

  const handleNavLinkClick = (path) => {
    navigate(path);
    closeMenu();
  };

  const isActive = (path) => {
    return location.pathname === path ? 'active' : '';
  };

  const toggleMenu = () => {
    setMenuOpen(prevIsMenuOpen => !prevIsMenuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const handleDropdownHover = (dropdownName) => {
    if (!isTouchDevice && !isMobile) {
      setActiveDropdown(dropdownName);
    }
  };

  const handleDropdownLeave = () => {
    if (!isTouchDevice && !isMobile) {
      setActiveDropdown(null);
    }
  };

  return (
    <Navbar expand="lg" className="navbar-full-width p-0">
      <Container className="navbar-container">
        {isMobile && (
          <Navbar.Brand className="navbar-image-logo p-0" onClick={() => handleNavLinkClick('/')}>
            <img src={Logo} alt="Logo" width={114} height={80} />
          </Navbar.Brand>
        )}

        <Navbar.Toggle aria-controls="basic-navbar-nav" className="navbar-toggler" onClick={toggleMenu}>
          {isMenuOpen ? <FontAwesomeIcon icon={faTimes} /> : <FontAwesomeIcon icon={faBars} />}
        </Navbar.Toggle>

        <Navbar.Collapse id="basic-navbar-nav" in={isMenuOpen}>
          <Nav className="navbar-nav">
            <NavLink className={`nav-link mx-4 ${isActive('/')}`} to="/" onClick={() => handleNavLinkClick('/')}>
              Home
            </NavLink>

            <NavDropdown
              title="About Us"
              id="dropdown-aboutus"
              show={isMobile ? activeDropdown === 'AboutUs' : undefined}
              onMouseEnter={() => handleDropdownHover('AboutUs')}
              onMouseLeave={handleDropdownLeave}
              onClick={() => handleDropdownToggle('AboutUs')}
              className={`custom-dropdown mx-4 ${isActive('/about-us') || isActive('/why-ncsg') ? 'active' : ''} ${activeDropdown === 'AboutUs' ? 'show' : ''}`}
            >
              <NavDropdown.Item className={isActive('/about-us')} onClick={() => handleNavLinkClick('/about-us')}>
                About
              </NavDropdown.Item>
              <NavDropdown.Item className={isActive('/why-ncsg')} onClick={() => handleNavLinkClick('/why-ncsg')}>
                Why NCSG
              </NavDropdown.Item>
            </NavDropdown>

            <NavDropdown
              title="SERVICES"
              id="dropdown-services"
              show={isMobile ? activeDropdown === 'Services' : undefined}
              onMouseEnter={() => handleDropdownHover('Services')}
              onMouseLeave={handleDropdownLeave}
              onClick={() => handleDropdownToggle('Services')}
              className={`custom-dropdown mx-4 ${isActive('/mobile-patrol') || isActive('/fire-wardens') ? 'active' : ''} ${activeDropdown === 'Services' ? 'show' : ''}`}
            >
              <NavDropdown.Item className={isActive('/mobile-patrol')} onClick={() => handleNavLinkClick('/mobile-patrol')}>
                MOBILE PATROL
              </NavDropdown.Item>
              <NavDropdown.Item className={isActive('/fire-wardens')} onClick={() => handleNavLinkClick('/fire-wardens')}>
                FIRE WARDENS
              </NavDropdown.Item>
              <NavDropdown.Item className={isActive('/cctv-operationsc')} onClick={() => handleNavLinkClick('/cctv-operations')}>
              CCTV Operations
              </NavDropdown.Item>
              <NavDropdown.Item className={isActive('/concierge-services')} onClick={() => handleNavLinkClick('/concierge-services')}>
              Concierge & Customer<br/> Services
              </NavDropdown.Item>
              <NavDropdown.Item className={isActive('/other-services')} onClick={() => handleNavLinkClick('/other-services')}>
              Other's Services
              </NavDropdown.Item>
            </NavDropdown>

          

            <NavLink className={`nav-link mx-4 ${isActive('/clients')}`} to="/clients" onClick={() => handleNavLinkClick('/clients')}>
              Clients
            </NavLink>

            <NavLink className={`nav-link mx-4 ${isActive('/careers')}`} to="/careers" onClick={() => handleNavLinkClick('/careers')}>
              Career
            </NavLink>
           
            <NavLink className={`nav-link mx-4 ${isActive('/contact-us')}`} to="/contact-us" onClick={() => handleNavLinkClick('/contact-us')}>
              Contact Us
            </NavLink>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;