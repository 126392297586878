import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./Home3Comp.css";

import image1 from "../../assets/cl1.png";
import image2 from "../../assets/cl2.png";
import image3 from "../../assets/cl3.png";
import image4 from "../../assets/cl4.png";
import image5 from "../../assets/cl5.png";
import image6 from "../../assets/cl6.png";
import image7 from "../../assets/cl7.png";
import image8 from "../../assets/cl8.png";

const Home3Comp = () => {
  const images = [
    { path: image1 },
    { path: image2 },
    { path: image3 },
    { path: image4 },
    { path: image5 },
    { path: image6 },
    { path: image7 },
    { path: image8 }
  ];

  useEffect(() => {
    AOS.init({ 
      duration: 1000, // Duration of the animation
      easing: 'ease-in-out', // Easing function
      once: false // Whether animation should happen only once
    });
  }, []);

  return (
    <div className="cpws-main-wrapper">
      <div className="cpws-header-wrapper">
        <h2 className="cpws-title" data-aos="fade-up">Trusted By World's Companies</h2>
        <span className="cpws-divider" data-aos="fade-up" data-aos-delay="100"></span>
      </div>
      <div className="cpws-grid-wrapper">
        {images.map((image, index) => (
          <div 
            className="cpws-grid-item" 
            key={index}
            data-aos="fade-up"
            data-aos-delay={(index + 1) * 200}
          >
            <img
              src={image.path}
              alt={`Project ${index + 1}`}
              className="cpws-image"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Home3Comp;
