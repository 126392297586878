import React, { useEffect } from 'react';
import './Whowe2Comp.css';
import missionImage from '../../assets/2.jpg';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Whowe2Comp = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000, // animation duration in milliseconds
      easing: 'ease-in-out', // animation easing
      once: false, // whether animation should only happen once
    });
  }, []);

  return (
    <div className="whowe2comp-wrapper">
      <div className="whowe2comp-content">
        <div className="whowe2comp-mission-image" data-aos="fade-up" data-aos-delay="1000">
          <div className="gray-bg-box"></div>
          <img src={missionImage} alt="mission" />
        </div>
        <div className="whowe2comp-mission" data-aos="fade-up" data-aos-delay="1200">
          <h2 className="mission-heading">Our Mission</h2>
          <ul className="mission-points">
            <li data-aos="fade-up" data-aos-delay="1400">Our company mission is to deliver comprehensive security solutions to all our clients through a personalized service designed to meet their specific requirements.</li>
            <li data-aos="fade-up" data-aos-delay="1600">Above all, we prioritize the safety and security of our clients' tenants, customers, staff, premises, assets, and the general public as our utmost priority.</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Whowe2Comp;
