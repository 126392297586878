import React, { useEffect } from 'react';
import './Home1Comp.css';
import { SlArrowRightCircle } from "react-icons/sl";
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS CSS

import companyImage from '../../assets/unit-1.png'; 
import { useNavigate } from 'react-router-dom'

const Home1Comp = () => {
  const navigate = useNavigate(); 

  useEffect(() => {
    AOS.init({
      duration: 1000, // Change the animation duration as needed
      disable: window.innerWidth <= 768, // Disable AOS on screens smaller than or equal to 768px
    });
  }, []);
  
  const handleNavigation = (path) => {
    navigate(path); 
  };

  return (
    <div className="unit1-container" data-aos="fade-up"> {/* Add AOS attribute */}
      <div className="left-content" data-aos="fade-right">
      <h1> ABOUT US</h1>
        <div className='company-info'>
           At National Capital we are proud to provide  complete security services to a range of national organizations including merchants, significant large commercial enterprises, organizations, property management businesses, banks financial institutions, hotels, gambling venues licensed clubs, logistic companies, and the public sector.
        </div>
        
        <button className="about-company-button" onClick={() => handleNavigation('/about-us')} data-aos="zoom-in"> {/* Add AOS attribute */}
          About Company 
          <SlArrowRightCircle className="rightcircle-icon"  />
        </button>
      </div>
      <div className="right-content" data-aos="fade-up"> {/* Add AOS attribute */}
        <img className="company-image zoomer-mover" src={companyImage} alt="Company" />
      </div>
    </div>
  );
};

export default Home1Comp;
