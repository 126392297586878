import React, { useEffect } from 'react';
import { SlArrowRightCircle } from "react-icons/sl";
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS CSS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import companyImage from '../../assets/service.jpg'; 
import { useNavigate } from 'react-router-dom';
import MainComp from './MainFireComp';

const Concierge = () => {
  const navigate = useNavigate(); 

  useEffect(() => {
    AOS.init({
      duration: 1000, // Change the animation duration as needed
      disable: window.innerWidth <= 768, // Disable AOS on screens smaller than or equal to 768px
    });
  }, []);
  
  const handleNavigation = (path) => {
    navigate(path); 
  };

  return (
    <>
      <MainComp/>
      <div className="mobilePatrol-container" data-aos="fade-up"> {/* Add AOS attribute */}
        <div className="mobilePatrol-left-content" data-aos="fade-right">
          <h1> Concierge and Customer Service</h1>
          <div className='mobilePatrol-company-info'>
            National Capital Security Group believes that providing outstanding experiences requires a 
            personal touch. Our concierge and customer service guards are professional, friendly, and
            approachable, ensuring that your customers have a positive and lasting impression.
            <br />The following are some of our concierge and customer service solutions:<br/><br/>
            <div className="points-container">
              {[
                "Corporate reception duties",
                "Meeting and greeting customers",
                "Signing in and escorting visitors",
                "Administration assistance ",
                "Booking meeting rooms",
                "Allocating visitor parking"
              ].map((point, index) => (
                <div className="point" key={index} data-aos="fade-up" data-aos-delay={`${index * 200}`}>
                  <div className="bullet-image">
                    <FontAwesomeIcon icon={faCheck} className="bullet-point" />
                  </div>
                  <div className="point-text">{point}</div>
                </div>
              ))}
            </div>
            <button className="mobilePatrol-about-company-button" onClick={() => handleNavigation('/contact-us')} data-aos="zoom-in"> {/* Add AOS attribute */}
              Contact Us
              <SlArrowRightCircle className="mobilePatrol-rightcircle-icon" />
            </button>
          </div>
        </div>
        <div className="mobilePatrol-right-content" data-aos="fade-up"> {/* Add AOS attribute */}
          <img className="mobilePatrol-company-image" src={companyImage} alt="Company" />
        </div>
      </div>
    </>
  );
};

export default Concierge;
