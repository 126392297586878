import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook
import AOS from 'aos';
import 'aos/dist/aos.css';
import './HeroSection.css';
import image1 from "../../assets/1.webp";
import image2 from "../../assets/3.jpg";
import image3 from "../../assets/5.jpg";

const HeroSection = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = [image1, image2, image3];
  const navigate = useNavigate(); // Initialize useNavigate hook

  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: 'ease-in-out',
      once: true,
    });

    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  const handleAboutUsClick = () => {
    // Navigate to the About Us page when the button is clicked
    navigate('/about-us');
  };

  return (
    <div className="hero-section" style={{ backgroundImage: `url(${images[currentImageIndex]})` }}>
      <div className="overlay"></div>
      <div className="hero-content">
        <div
          className="integrated-facility-services"
          data-aos="fade-down"
          data-aos-delay="300"
        >
         Your Safety And Comfort Is Our Priority
        </div>
        <div
          className="southern-cross-group"
          data-aos="fade-up"
          data-aos-delay="600"
        >
          National Capital Security Group
        </div>
        <button
          className="about-us-button"
          data-aos="fade-up"
          data-aos-delay="900"
          onClick={handleAboutUsClick} 
        >
          ABOUT US
        </button>
      </div>
    </div>
  );
};

export default HeroSection;
