import React, { useEffect, useRef } from 'react';
import './WhyncsgComp1.css';
import { points } from './PointFile';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons'; // Import the check icon
import AOS from 'aos';
import 'aos/dist/aos.css';

const WhyncsgComp1 = () => {
  const pointsRef = useRef([]);

  useEffect(() => {
    AOS.init({ once: true }); // Initialize AOS once
  }, []);

  return (
    <div className="why-hts-wrapper">
      <div className="why-hts-points">
      <h1>WHY NATIONAL CAPITAL SECURITY GROUP</h1>
      <h2>Our company's approach is different from conventional security companies. Before the actual organizations are mentioned, we would like to highlight what makes us different from others in the business</h2>
        {points.map((point, index) => (
          <div key={index} className="why-hts-point" ref={(el) => (pointsRef.current[index] = el)} data-aos="fade-up">
            <FontAwesomeIcon icon={faCheck} className="check-icon" />
            <div className="why-hts-point-text">{point.text}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WhyncsgComp1;
