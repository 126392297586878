// MainComp.jsx

import React, { useEffect } from 'react';
import './MainComp.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

const MainComp = () => {
  useEffect(() => {
    AOS.init({ duration: 2000 }); // Initialize AOS and set duration for the animation
  }, []);

  return (
    <div className="mainAbout-section">
      <div className="overlay" ></div> {/* Add fade-up AOS effect here */}
      <div className='main-title-Aboutsection' data-aos="fade-down">
        <h1 className="main-title">About Us</h1>
      </div>
    </div>
  );
};

export default MainComp;
