import React from "react";

import MainComp from "../components/About/MainComp/MainComp";
import About1Comp from '../components/About/About1comp/About1comp'
import Whowe1Comp from "../components/About/WhoweComp/Whowe1Comp";
import Whowe2Comp from "../components/About/WhoweComp/Whowe2Comp";
import Whowe3Comp from "../components/About/WhoweComp/Whowe3Comp";
import About2Comp from '../components/About/About2Comp/About2Comp';

const About = () => {
  return (
    <div>
      <MainComp/>
      <About1Comp/>
      <About2Comp/>
      <Whowe3Comp/>
      <Whowe2Comp/>
      <Whowe1Comp/>
    
    </div>
  );
};

export default About;
