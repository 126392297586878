import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './Home5Comp.css';

const Home5Comp = () => {

  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration in milliseconds
      easing: 'ease-in-out', // Animation easing
      once: false, 
    });
  }, []);



  return (
    <div className="why-hts-container">
    
     <h1>WHY NATIONAL CAPITAL SECURITY GROUP ?</h1>
      <div className='content-section'  data-aos="fade-up"  data-aos-delay="400">
      <div className="content-container">
      National Capital Security Group (NCSG) is a leading security services provider known for its commitment to excellence, professionalism, and customer satisfaction. Here are some key reasons why NCSG stands out:
      </div>
      </div>
    </div>
  );
};

export default Home5Comp;
