import React from "react";
import HeroSection from "../components/Home/HeroSection/HeroSection";
import Home1Comp from "../components/Home/Home1Comp/Home1Comp";
import Home2Comp from "../components/Home/Home2Comp/Home2Comp";
import Home3Comp from "../components/Home/Home3Comp/Home3Comp";
import Home4Comp from "../components/Home/Home4Comp/Home4Comp";
import Home5Comp from "../components/Home/Home5Comp/Home5Comp";
import FAQComponent from "../components/FAQComponent/FAQComponent";
import CustomerReviews from "../components/CustomerReviews/CustomerReviews";

const Home = () => {
  return (
    <div>
      <HeroSection />
      <Home1Comp/>
      <Home5Comp/>
      <Home4Comp/>
 <Home2Comp/>

 <Home3Comp/>
 <CustomerReviews/>
 <FAQComponent/>
 
    </div>
  );
};

export default Home;
