import React, { useEffect } from 'react';
import './Whowe1Comp.css';
import visionImage from '../../assets/ourvalues.jpg';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Whowe1Comp = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000, // animation duration in milliseconds
      easing: 'ease-in-out', // animation easing
      once: false, // whether animation should only happen once
    });
  }, []);

  return (
    <div className="whowe1comp-wrapper">
      
      <div className="whowe1comp-content">
        <div className="whowe1comp-vision" data-aos="fade-up" data-aos-once="true">
          <h2 className="vision-heading" data-aos-delay="300">OUR VALUES</h2>
          <ul className="vision-points">
            <li data-aos="fade-up" data-aos-delay="600">We prioritize accountability to our clients, staff, and partners. We honor our commitments, delivering tangible results, and relentlessly pursue excellence in providing top-tier security services.</li>
            <li data-aos="fade-up" data-aos-delay="800">As both a company and as individuals, we uphold values such as honesty, integrity, professionalism, and mutual respect. These principles guide our actions, ensuring we remain dedicated to our mission and maintain the trust of all stakeholders.</li>
          </ul>
        </div>
        <div className="whowe1comp-vision-image" data-aos="fade-up" data-aos-once="true" data-aos-delay="1000">
          <div className='graybg'></div>
          <img src={visionImage} alt="Vision" className="half-in-half-out" />
        </div>
      </div>
    </div>
  );
};

export default Whowe1Comp;
