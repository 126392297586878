import React from 'react';
import './OtherServices.css';

const OtherServices = () => {
  return (
    <div className="other-services-container">
      <div className="service-section">
        <h2>FIRST AID RESPONSE</h2>
        <p>
          Multidisciplinary Intensive Care Paramedics (flight certified available), ALS Paramedics and Industrial Medics (i.e. ESO's), Occupational First Responders skilled in emergency response, and emergency response team assistance and training are among the emergency health services offered.
        </p>
      </div>
      <div className="service-section">
        <h2>LOSS PREVENTION OFFICERS</h2>
        <p>
          National Capital Security Group can design and implement solutions to help clients reduce their losses and, as a result, increase their profits.
        </p>
      </div>
      <div className="service-section">
        <h2>STAFF FOR THE EVENT</h2>
        <p>
          Officers in charge of crowd control, emergency personnel, traffic and parking management, ticket and turnstile operators, as well as event planning and consulting.
        </p>
      </div>
      <div className="service-section">
        <h2>MOBILE PATROLS & EVENT RESPONSES</h2>
        <p>
          The National Capital Security Group is backed by Australia's largest fleet of marked security vehicles, which conduct random inspections of unattended assets while ensuring the most efficient response to duress and alarm activations through the use of cutting-edge dispatch, navigation, and reporting technology.
        </p>
      </div>
      <div className="service-section">
        <h2>CUSTOMER SERVICE OFFICERS</h2>
        <p>
          The concierge, front-of-house, and customer service workers at National Capital Security Group are well dressed and create a cheerful and welcoming atmosphere.
        </p>
        <p>
          When it comes to hiring, it's critical to understand the function and select the ideal sort of individual for it. After all, these personnel will be our clients' brand ambassadors. To achieve this, our specialized HR staff frequently recruits people with customer service or hospitality expertise from outside the security field, and then provides them with the required security training.
        </p>
      </div>
      <div className="service-section">
        <h2>CONTROL ROOMS</h2>
        <p>
          National Capital Security Group personnel monitor site security systems, building management systems, and closed-circuit television systems (CCTV). These personnel complete specific training, which includes the operation, monitoring, and testing of alarm panels, security cameras, monitoring systems, and communications.
        </p>
      </div>
      <div className="service-section">
        <h2>ACCESS CONTROL</h2>
        <p>
          Security officers who ensure the integrity of controlled access points by denying entry of all unauthorized personnel. Key elements include registering visitors, production of access and photo identification cards, conducting identification checks and searches. Comprehensive guidelines are followed for differing security threat levels.
        </p>
      </div>
    </div>
  );
};

export default OtherServices;
