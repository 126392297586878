import React from 'react';
import './Client1Comp.css';

// Import client logo images from assets
import cl1 from '../assets/cl1.png';
import cl2 from '../assets/cl2.png';
import cl3 from '../assets/cl3.png';
import cl4 from '../assets/cl4.png';
import cl5 from '../assets/cl5.png';
import cl6 from '../assets/cl6.png';
import cl7 from '../assets/cl7.png';
import cl8 from '../assets/cl8.png';

const Client1Comp = () => {
    // Array of client logo images
    const clientLogos = [
        cl1,
        cl2,
        cl3,
        cl4,
        cl5,
        cl6,
        cl7,
        cl8
    ];

    return (
        <div className="client-container">
            <h5 className="client-mini-title">OUR CLIENT'S</h5>
            <h2 className="client-heading">TRUSTED BY WORLD'S COMPANIES</h2>
            <div className="client-grid">
                {clientLogos.map((logo, index) => (
                    <div key={index} className="client-item">
                        <img src={logo} alt={`Company Logo ${index + 1}`} className="client-logo" />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Client1Comp;
