export const points = [
  {
    text: 'We take pride in our work.'
  },
  {
    text: "We don't have a fleet of hundreds of guards, which makes an employee a number rather than a part of a team"
  },
  {
    text: "We don't sub-contract our work out, nor we will. This ensures that every employee participates not only like a family in the team but goes well above and beyond to help not only the company but the client. This also ensures quality, as we have more control over our workforce, which allows us flexibility, taking pride in the work performed and above all the client satisfaction. Our aim is to beat the requirements of the job description. Our employees are there to satisfy the client needs, not showing up like a 'body'."
  },
  {
    text: 'Our client base is small; this allows us to focus and provide the best customer experience to our clients. We listen to our clients; we provide them with the reports, which becomes helpful for them. We are always "on-call" to them, should they need an extra guard. We organise meetings with them to hear any feedback, any issues that may require improvement or just a quick chat; we are at their service.'
  },
  {
    text: 'We provide the rosters of the site to the client a week before. This allows surety and avoids any surprises. The client knows who the guard will be on-site with their contact details. There is no such thing as hidden costs/surprises to the Manager on site. Unless if there is an Adhoc request, even then, we send our clients full details of the guards. Every site has the full details of the guard, along with the quick daily timesheet approvals. This ensures that the client has a visibility of who is on-site and how long they have worked.'
  },
  {
    text: 'Our rostering system is the best in the industry due to one main reason; it is the most accurate system one can get. It prevents the double booking of a guard. It also geographically locks the site with the help of google maps. Security award is already locked in against each employee, which links with the roster allowing the system to automatically calculate the time of the shift, the applicable rate as per award at that time and any allowances payable. This ensures less work for the accountants to check and results in greater administrative efficiencies'
  },
  {
    text: "The quality of service will speak for itself. The main reason, we are always on our toes. We have weekly catch-up s with the guards. Apart from that, we always answer their calls and listen to their grievances, if any. They also get 'goody rewards' such as Coffee and Sandwich's from 7/11 on the house, Nando's gift card, fuel card and sometimes, $100 gift card."
  },
  {
    text: "Our interview process is quite rigorous. As much as we believe in expending our workforce, we also provide the opportunity to our current staff members if they need additional shifts. This helps them earn extra and provides them with happiness as they reach closer to their financial goals. This also helps us keep the same guard on the same site"
  },
  {
    text: 'You will never have to "chase" us. By providing the client with the details in advance, along with the guards who are inducted, briefed, and the role played during the interview process, there is a little chance of something going wrong.'
  },
  {
    text: "Our invoices are matched with our timesheet, which gets approved by the client, hence paying off our invoices is seamless."
  },
  {
    text: "Our guard team is something we feel pride in. in the past two years, we only had to lay off one team member purely because of his not wearing a proper uniform."
  },
  {
    text: "Our guards really like to work with us because they get paid on time every time, they get looked after well by our reward system. They are properly interviewed; if not a PR or Citizen, their work requirements are checked, and restrictions are placed on the system accordingly."
  },
  {
    text: "We can assure you that you will feel the difference; the sites will have guards who are happy, who will know what they are doing and why they are doing it"
  }
];
