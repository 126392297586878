import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./Home2Comp.css";
import { Link } from "react-router-dom"; // Import Link for navigation
import image1 from "../../assets/patrolling.webp";
import image2 from "../../assets/firewarden.jpeg";
import image3 from "../../assets/cctv.webp";
import image4 from "../../assets/4.jpg";
import image5 from "../../assets/first-aid.jpg";
import image6 from "../../assets/loss.webp";
import image7 from "../../assets/accescontrol.jpeg";
import image8 from "../../assets/controlroom.jpeg";
import image9 from "../../assets/eventstaff.jpeg";

const Home2Comp = () => {
  const images = [
    { path: image1, title: "Motor Patrols", link: "/mobile-patrol" },
    { path: image2, title: "Fire Wardens", link: "/fire-wardens" },
    { path: image3, title: "CCTV Operations", link: "/cctv-operations" },
    { path: image4, title: "Concierge & Customer Service", link: "/concierge-services" },
    { path: image5, title: "First Aid Response", link: "/other-services" },
    { path: image6, title: "Loss Prevention Officers", link: "/other-services" },
    { path: image7, title: "Access Control", link: "/other-services" },
    { path: image8, title: "Control Rooms", link: "/other-services" },
    { path: image9, title: "Event Staff", link: "/other-services" }
  ];

  useEffect(() => {
    AOS.init({ 
      duration: 1000, // Duration of the animation
      easing: 'ease-in-out', // Easing function
      once: false // Whether animation should happen only once
    });
  }, []);

  return (
    <div className="what-we-do-container">
      <div className="title-container">
        <h2 className="home2-title" data-aos="fade-up">Services We Provide</h2>
        <span className="divider-separator" data-aos="fade-up" data-aos-delay="100"></span>
        <h3 className="home2-mini-title" data-aos="fade-up" data-aos-delay="200">Proudly delivering Security Guard Services across Australia & consistently delivering professional services since 2019.</h3>
      </div>
      <div className="grid-container">
        <div className="grid">
          {images.map((image, index) => (
            <Link 
              to={image.link} 
              className="grid-item" 
              key={index}
              data-aos="fade-up"
              data-aos-delay={(index + 1) * 200}
            >
              <img
                src={image.path}
                alt={`Image ${index + 1}`}
                className="wwd-image"
              />
              <h3 className="image-title">{image.title}</h3>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Home2Comp;
