import React, { useEffect } from 'react';
import './Whowe3Comp.css';
import qualityImage from '../../assets/vision.jpg';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Whowe3Comp = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000, // animation duration in milliseconds
      easing: 'ease-in-out', // animation easing
      once: false, // whether animation should only happen once
    });
  }, []);

  return (
    <div className="whowe3comp-wrapper">
      <div className="whowe3comp-content">
        <div className="whowe3comp-quality" data-aos="fade-up" data-aos-delay="200">
          <h2 className="quality-heading">Our Vision</h2>
          <ul className="quality-points">
            <li data-aos="fade-up" data-aos-delay="400">Our vision is to be the trusted leader in providing top-quality security services throughout Australia. We're dedicated to earning our clients' trust by consistently delivering excellence in every aspect of our services.</li>
            <li data-aos="fade-up" data-aos-delay="600">Through our unwavering commitment to the highest standards, we aim to set new benchmarks in the industry. Our goal is simple: to ensure our clients feel safe and secure, knowing they can rely on us for the best-in-class security solutions.</li>
          </ul>
        </div>
        <div className="whowe3comp-quality-image" data-aos="fade-up" data-aos-delay="800">
          <div className="image-overlay"></div>
          <img src={qualityImage} alt="Quality" className="quality-image" />
        </div>
      </div>
    </div>
  );
};

export default Whowe3Comp;
